import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {getSize} from 'util/file.js'

const styles = {
  noBreak: {
    wordBreak: 'normal',
    whiteSpace: 'nowrap'
  }
}

function FileSize({bytes}) {
  const {t} = useTranslation('common', {keyPrefix: 'fileSizes'})
  const {value, unit} = getSize(bytes)

  return (
    <span style={styles.noBreak}>
      {unit
        ? t(`${unit}`, {size: value})
        : t('overflow')}
    </span>
  )
}

FileSize.propTypes = {
  bytes: PropTypes.number.isRequired
}

export default FileSize
