import {ClockCircleOutlined, TeamOutlined, ImportOutlined, WarningOutlined} from '@ant-design/icons'
import {Button, Flex, Space, Tag, Tooltip, Typography, theme} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {styled} from 'styled-components'

import api from 'services/api/index.js'

import {getProjectUrl, getWorkspaceUrl} from 'helpers/url.js'

import {getTimeSince} from 'util/date.js'

import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'
import UsersAvatarGroup from 'components/display/avatar/users-avatar-group.js'

const {Text} = Typography

const WorkspaceOverviewStyled = styled(Flex)`
  border: 1px solid ${({theme}) => theme.antd.colorBorderSecondary};

  .avatar {
    background-color: ${({theme}) => theme.antd.colorBGAvatar};
  }

  .infos {
    border-bottom: 1px solid ${({theme}) => theme.antd.colorBorderSecondary};
    padding: ${({theme}) => theme.antd.paddingXS}px ${({theme}) => theme.antd.padding}px;
  }

  .full-width {
    width: 100%;
  }

  .footer {
    padding: ${({theme}) => theme.antd.paddingXS}px;
  }
`
WorkspaceOverviewStyled.displayName = 'WorkspaceOverview'

function WorkspaceOverview({workspace, owner, isShuttingDownSoon = false, members, maxWidth}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkspaceOverview'

  const {token} = theme.useToken()

  const {project, workflow, createdAt} = workspace

  const avatar = api.getAvatarURL(owner, 'small')

  return (
    <WorkspaceOverviewStyled
      vertical
      style={{
        maxWidth: maxWidth || '400px'
      }}
    >
      <Flex
        justify='space-between'
        align='center'
        gap={token.marginXL}
        className='infos'
      >
        <Space
          direction='vertical'
          size={token.paddingXXS}
        >
          <Link to={getProjectUrl(project)}>
            <Text strong underline>{project.title}</Text>
          </Link>
          <Text><small>{workflow.title}</small></Text>
        </Space>
        <Tooltip title={owner.displayName}>
          {owner.type === 'organization' ? (
            <OrganizationAvatar avatar={avatar} size='default'/>
          ) : (
            <UserAvatar avatar={avatar} size='default'/>
          )}
        </Tooltip>
      </Flex>
      <Space
        direction='vertical'
        className='infos'
      >
        <Flex justify='space-between'>
          <Space>
            <ClockCircleOutlined/>
            <Text>{t('common:date.createdSince')}{t('common:date.since', {since: getTimeSince(createdAt)})}</Text>
          </Space>

          {isShuttingDownSoon && (
            <Tag color='orange' icon={<WarningOutlined/>}>
              {t(`${keyPrefix}.isShuttingDownSoon`)}
            </Tag>
          )}
        </Flex>
        <Space align='center'>
          <TeamOutlined/>
          <Text>{t(`${keyPrefix}.members`)}</Text>
          <UsersAvatarGroup profiles={members}/>
        </Space>
      </Space>
      <Flex
        justify='center'
        className='footer'
      >
        <Link to={getWorkspaceUrl(project, workflow, workspace)} className='full-width'>
          <Button
            type='text'
            icon={<ImportOutlined/>}
            className='full-width'
          >
            {t(`${keyPrefix}.join`)}
          </Button>
        </Link>
      </Flex>
    </WorkspaceOverviewStyled>
  )
}

WorkspaceOverview.propTypes = {
  workspace: PropTypes.object.isRequired,
  owner: PropTypes.shape({
    type: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }).isRequired,
  isShuttingDownSoon: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    avatar: PropTypes.oneOfType([PropTypes.string])
  })).isRequired,
  maxWidth: PropTypes.string
}

export default WorkspaceOverview
