/* eslint-disable react/prop-types */

import React from 'react'

import {useTranslation} from 'react-i18next'
import Markdown from 'react-markdown'

import SidebarSection from 'components/layouts/sidebar-section.js'

function WorkflowDescriptionSection({description, isUserCanEdit, edit}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkflowEditor.WorkflowDescriptionSection'})

  return (
    <SidebarSection
      title={t('title')}
      isPlaceholderDisplayed={!description}
      placeholderImage='/images/description-placeholder.svg'
      placeholderImageAlt={t('descriptionIllustrationAlt')}
      actionButtonLabel={(!description || isUserCanEdit) && t('addDescription')}
      handleAction={!description && isUserCanEdit ? edit : null}
    >
      {description && (
        <Markdown>{description}</Markdown>
      )}
    </SidebarSection>
  )
}

export default React.memo(WorkflowDescriptionSection)
