import Icon from '@ant-design/icons'
import {Tag, Typography, Space, Flex} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import Markdown from 'components/markdown.js'

import Container from 'containers/layout/container.js'
import VisibilityTag from 'containers/ui/visibility-tag.js'

import {ReactComponent as DatasetIcon} from 'assets/images/dataset-icon-purple.svg'

const {Title} = Typography

const StyledTag = styled(Tag)`
  font-size: ${({theme}) => theme.antd.fontSizeLG}px;
  padding: ${({theme}) => theme.antd.paddingXXS}px ${({theme}) => theme.antd.paddingMD}px;
`

function ProjectSummary({title, visibility, description, type}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectSummary'})
  const isDataset = type === 'dataset'

  return (
    <Container background='secondary'>
      <Flex vertical>
        <Flex justify='space-between'>
          <Space size='large'>
            <Title level={1}>{title}</Title>
            <VisibilityTag visibility={visibility}/>
          </Space>
          {isDataset && (
            <Space>
              <StyledTag color='purple'>
                <Icon component={DatasetIcon}/>
                <span>{t('dataset')}</span>
              </StyledTag>
            </Space>
          )}
        </Flex>
        <Markdown>{description || t('description')}</Markdown>
      </Flex>
    </Container>
  )
}

ProjectSummary.propTypes = {
  title: PropTypes.string.isRequired,
  visibility: PropTypes.oneOf(['public', 'private']).isRequired,
  description: PropTypes.string,
  type: PropTypes.string
}

export default ProjectSummary
