/* eslint-disable react/prop-types */

import React from 'react'

import Icon, {UserOutlined, RobotOutlined, LinkOutlined, HistoryOutlined, FileOutlined, GoldOutlined} from '@ant-design/icons'
import {Flex, Typography, Avatar, Tooltip, Tag, theme} from 'antd'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {getProjectFilesContainerUrl} from 'helpers/url.js'

import {getRelativeTimeFormat} from 'util/date.js'

import FileSize from 'components/ui/file-size.js'

const {Text} = Typography

const styles = {
  noMargin: {
    margin: 0
  }
}

const Container = styled.div`
  border: 1px solid ${({theme}) => theme.antd.colorBorder};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingXS}px;
`

function ContainerCard({container}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.ContainerCard'
  const {name, fileList, updatedAt, isDeprecated} = container

  const {token} = theme.useToken()

  return (
    <Container>
      <Flex vertical gap='large'>
        <Flex justify='space-between' align='center' wrap='wrap' gap='small'>
          <Link to={getProjectFilesContainerUrl({_id: container.project}, container)}>
            <Text
              strong
              underline
              copyable={{
                icon: [<LinkOutlined key='copy'/>],
                tooltips: [t('common:copyableTooltip.copy'), t('common:copyableTooltip.copied')]
              }}
            >
              {name}
            </Text>
          </Link>

          {isDeprecated && (
            <Tag color='red' style={styles.noMargin}>{t(`${keyPrefix}.deprecated`).toUpperCase()}</Tag>
          )}
        </Flex>

        <Flex justify='space-between' align='center' gap='small'>
          <Flex gap='small' wrap='wrap'>
            <Flex noWrap align='center' gap='small'>
              <Icon component={HistoryOutlined}/>
              <Text style={{textWrap: 'nowrap'}}>{getRelativeTimeFormat(updatedAt)}</Text>
            </Flex>

            <Flex noWrap align='center' gap='small'>
              <Icon component={FileOutlined}/>
              <Text style={{textWrap: 'nowrap'}}>{t(`${keyPrefix}.filesCount`, {count: fileList.itemCount})}</Text>
            </Flex>

            <Flex noWrap align='center' gap='small'>
              <Icon component={GoldOutlined}/>
              <FileSize style={{textWrap: 'nowrap'}} bytes={fileList.itemsSize}/>
            </Flex>
          </Flex>

          <div style={{minWidth: 20, minHeight: 20}}>
            <Tooltip title={t(`${keyPrefix}.${fileList.type === 'user' ? 'uploaded-by-producer' : 'computed-by-workflow'}`)}>
              <Avatar
                size='small'
                style={{backgroundColor: token.colorPrimary}}
                icon={fileList.type === 'user' ? <UserOutlined/> : <RobotOutlined/>}
              />
            </Tooltip>
          </div>
        </Flex>
      </Flex>
    </Container>
  )
}

export default React.memo(ContainerCard)
