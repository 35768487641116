import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'

import {Flex, message} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import ReactFlow, {
  MarkerType,
  useReactFlow,
  Background,
  Controls,
  BackgroundVariant,
  useNodesState,
  useEdgesState,
  useNodesInitialized
} from 'reactflow'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {isOperationCompatible} from 'util/operations.js'
import {createEdgesFromNodes, createNodeFromOperation, getLayoutedElements, proOptions} from 'util/react-flow.js'

import 'reactflow/dist/style.css'
import 'react-flow-style.css'

import OperationsContext from 'contexts/operations-context.js'
import ProjectContext from 'contexts/project.js'
import WorkflowContext from 'contexts/workflow-context.js'

import CustomEdge from 'components/node/custom-edge.js'

import CustomNode from 'containers/react-flow/nodes/custom-node.js'
import InputNode from 'containers/react-flow/nodes/input-node.js'
import OutputNode from 'containers/react-flow/nodes/output-node.js'
import PlaceholderNode from 'containers/react-flow/nodes/placeholder-node.js'

const nodeTypes = {
  custom: CustomNode,
  'custom-input': InputNode,
  'custom-output': OutputNode,
  placeholder: PlaceholderNode
}

const edgeTypes = {
  custom: CustomEdge
}

const defaultEdgeOptions = {
  type: 'smoothstep',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20
  },
  pathOptions: {offset: 5}
}

const fitViewOptions = {duration: 400, includeHiddenNodes: true}

const ReactFlowContainerStyled = styled(Flex)`
  background-color: ${({theme}) => theme.antd.colorWhite};
`
ReactFlowContainerStyled.displayName = 'ReactFlowContainer'

function ReactFlowContainer({
  operations,
  isOperationsLoaded,
  currentWorkspaceId,
  isSideMenuOpen,
  selectedOperationId
}) {
  const {t} = useTranslation('translation', {keyPrefix: 'ReactFlow'})

  const {getNode, fitView} = useReactFlow()

  const [messageApi, contextHolder] = message.useMessage()

  const {availableOperations} = useContext(ProjectContext)
  const {workspace} = useContext(WorkflowContext)
  const {handleSelectOperation} = useContext(OperationsContext)

  const nodesInitialized = useNodesInitialized()

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [needLayout, setNeedLayout] = useState(false)

  const prevOperationsRef = useRef([])

  useEffect(() => {
    if (!isOperationsLoaded) {
      return
    }

    const prevOperations = prevOperationsRef.current
    const prevOperationIds = new Set(prevOperations.map(op => op._id))
    const currentOperationIds = new Set(operations.map(op => op._id))

    const operationsAdded = operations.some(op => !prevOperationIds.has(op._id))
    const operationsRemoved = prevOperations.some(op => !currentOperationIds.has(op._id))

    if (operationsAdded || operationsRemoved) {
      setNeedLayout(true)
    }

    prevOperationsRef.current = operations

    // Construction des nodes
    if (operations.length === 0) {
      setNodes([{
        id: 'placeholder',
        type: 'placeholder',
        position: {x: 0, y: 0},
        data: {operation: {}}
      }])
      setEdges([])
      return
    }

    setNodes(nodes => {
      const operationIds = new Set(operations.map(op => op._id))
      const existingNodes = nodes.filter(node => operationIds.has(node.id))
      const newNodes = operations.map(operation => {
        const existingNode = existingNodes.find(node => node.id === operation._id)
        if (existingNode) {
          return {
            ...existingNode,
            data: {
              ...existingNode.data,
              operation
            }
          }
        }

        return createNodeFromOperation(operation)
      })

      const newEdges = createEdgesFromNodes(newNodes)
      setEdges(newEdges)

      return newNodes
    })
  }, [operations, isOperationsLoaded, setNodes, setEdges])

  // Apply layout when is needed
  useEffect(() => {
    if (needLayout && nodesInitialized) {
      if (nodes.length === 0) {
        return
      }

      const layoutedElements = getLayoutedElements(nodes, edges, {
        direction: 'TB'
      })
      setNodes(layoutedElements.nodes)
      setEdges(layoutedElements.edges)
      setNeedLayout(false)
    }
  }, [needLayout, nodesInitialized, nodes, edges, setNodes, setEdges])

  // Fit view when side menu is opened/closed or workspace changes
  useEffect(() => {
    setTimeout(() => {
      window.requestAnimationFrame(() => fitView(fitViewOptions))
    }, 200)
  }, [isSideMenuOpen, currentWorkspaceId, fitView])

  // Handle node selection
  useEffect(() => {
    setNodes(prevNodes =>
      prevNodes.map(node => ({
        ...node,
        selected: node.data.operation._id === selectedOperationId
      }))
    )
  }, [selectedOperationId, setNodes])

  const onNodeClick = useCallback((event, node) => {
    const {data: {operation}} = node
    if (operation) {
      handleSelectOperation(operation._id)
    }
  }, [handleSelectOperation])

  const onNodesDelete = useCallback(async nodes => {
    try {
      await Promise.all(nodes.map(node => api.deleteOperation(node.id)))
    } catch (error) {
      messageApi.error(t('deleteNodeFail', {error}))
    }
  }, [messageApi, t])

  const onPaneClick = useCallback(() => {
    handleSelectOperation(null)
  }, [handleSelectOperation])

  const onConnect = useCallback(async ({source, target}) => {
    const sourceNode = getNode(source)
    const targetNode = getNode(target)
    try {
      await api.updateOperation(targetNode.data.operation._id, {input: sourceNode.data.operation._id})
      setNeedLayout(true)
    } catch (error) {
      messageApi.error(t('deleteNodeFail', {error}))
    }
  }, [getNode, messageApi, t])

  const isValidConnection = useCallback(({source, target}) => {
    if (source === target) {
      return false
    }

    const sourceOperationType = nodes.find(({id}) => id === source).data.operation.type
    const targetOperationType = nodes.find(({id}) => id === target).data.operation.type

    const sourceOperation = availableOperations.find(({type}) => type === sourceOperationType)
    const targetOperation = availableOperations.find(({type}) => type === targetOperationType)

    return isOperationCompatible(targetOperation, sourceOperation)
  }, [nodes, availableOperations])

  return (
    <ReactFlowContainerStyled flex={1}>
      {contextHolder}
      <ReactFlow
        fitView
        maxZoom={1.5}
        nodes={nodes}
        edges={edges}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        nodesDraggable={false}
        deleteKeyCode={workspace?.isActive ? 'Backspace' : null}
        isValidConnection={isValidConnection}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onPaneClick={onPaneClick}
        onNodeClick={onNodeClick}
        onNodesDelete={onNodesDelete}
      >
        <Controls showInteractive={false}/>
        <Background variant={BackgroundVariant.dots}/>
      </ReactFlow>
    </ReactFlowContainerStyled>
  )
}

ReactFlowContainer.propTypes = {
  operations: PropTypes.array.isRequired,
  currentWorkspaceId: PropTypes.string,
  selectedOperationId: PropTypes.string,
  isSideMenuOpen: PropTypes.bool.isRequired,
  isOperationsLoaded: PropTypes.bool.isRequired
}

export default ReactFlowContainer
