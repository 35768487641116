/* eslint-disable react/prop-types */

import React from 'react'

import {Typography, Flex, Divider} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const {Title} = Typography

const DangerZoneContainer = styled(Flex)`
    padding: ${({theme}) => theme.antd.padding}px;
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    border: 1px solid ${({theme}) => theme.antd.colorError};
`

function DangerZone({children}) {
  const {t} = useTranslation('translation', {keyPrefix: 'DangerZone'})

  return (
    <div>
      <Title level={3}>{t('title')}</Title>
      <DangerZoneContainer vertical>
        {React.Children.toArray(children).map((child, index, array) => (
          <div key={child.props.title}>
            {child}
            {index !== array.length - 1 && <Divider/>}
          </div>
        ))}
      </DangerZoneContainer>
    </div>
  )
}

export default DangerZone
