import React, {lazy} from 'react'

import * as Sentry from '@sentry/react'
import {RouterProvider, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, createBrowserRouter, Outlet} from 'react-router-dom'

import {accountOrganizationLoader} from 'routes/account/organizations.js'
import {accountProjectsLoader} from 'routes/account/projects.js'
import AccountLayout from 'routes/account-layout.js'
import {accountLoader} from 'routes/account.js'
import {homeLoader} from 'routes/home.js'
import Logout from 'routes/logout.js'
import {organizationSettingsLoader} from 'routes/organizations/organization/settings/index.js'
import {organizationLoader} from 'routes/organizations/organization.js'
import {profileLoader} from 'routes/profile.js'
import {newProjectLoader} from 'routes/projects/new.js'
import {projectFileContainersLoader} from 'routes/projects/project/files-containers.js'
import {workflowLoader} from 'routes/projects/project/workflow.js'
import {workflowsLoader} from 'routes/projects/project/workflows.js'
import {projectLoader} from 'routes/projects/project.js'
import RegistrationDone from 'routes/registration-done.js'
import SignIn from 'routes/sign-in.js'
import SignUp from 'routes/sign-up.js'
import SuccessfulRegistration from 'routes/successful-registration.js'
import {userOrganizationLoader} from 'routes/user/organizations.js'
import {userProjectsLoader} from 'routes/user/projects.js'
import UserLayout from 'routes/user-layout.js'

import api from 'services/api/index.js'

import AccountSettingsLayout from 'containers/account-settings-layout.js'
import PageLayout from 'containers/layout/page.js'
import ProjectLayout from 'containers/layout/project.js'
import OrganizationSettingsLayout from 'containers/organization-settings-layout.js'
import OrganizationTabsNavigation from 'containers/organization-tabs-navigation.js'
import RequireAuth from 'containers/require-auth.js'
import SuspensePage from 'containers/suspense-page.js'
import UserTabsNavigation from 'containers/user-tabs-navigation.js'

const Account = lazy(() => import('routes/account.js'))
const ErrorPage = lazy(() => import('routes/error.js'))
const Home = lazy(() => import('routes/home.js'))
const Profile = lazy(() => import('routes/profile.js'))
const NewOrganization = lazy(() => import('routes/organizations/new.js'))
const Organization = lazy(() => import('routes/organizations/organization.js'))
const OrganizationSettings = lazy(() => import('routes/organizations/organization/settings/index.js'))
const AccountProjects = lazy(() => import('routes/account/projects.js'))
const ProjectSettingsLayout = lazy(() => import('containers/project/project-settings-layout.js'))
const Workflows = lazy(() => import('routes/projects/project/workflows.js'))
const FilesContainers = lazy(() => import('routes/projects/project/files-containers.js'))
const ProjectSettings = lazy(() => import('routes/projects/project/settings.js'))
const ProjectDatasets = lazy(() => import('routes/projects/project/datasets.js'))
const Workflow = lazy(() => import('routes/projects/project/workflow.js'))
const NewProject = lazy(() => import('routes/projects/new.js'))
const Project = lazy(() => import('routes/projects/project.js'))
const AccountSettings = lazy(() => import('routes/account/settings.js'))
const Plan = lazy(() => import('routes/account/plan.js'))
const AccountOrganizations = lazy(() => import('routes/account/organizations.js'))
const AccountIntegrations = lazy(() => import('routes/account/integrations.js'))
const Members = lazy(() => import('routes/organizations/organization/members.js'))
const OrganizationProjects = lazy(() => import('routes/organizations/organization/projects.js'))
const UserOrganizations = lazy(() => import('routes/user/organizations.js'))
const UserProjects = lazy(() => import('routes/user/projects.js'))

const {SENTRY_DSN} = process.env

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  tracesSampleRate: 1
})

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

function App() {
  const parsedUrl = new URL(window.location.href)
  const params = parsedUrl.searchParams
  const sessionToken = params.get('sessionToken')

  // Store sessionToken and remove it from URL
  if (sessionToken) {
    api.storeSession({token: sessionToken})
    params.delete('sessionToken')
    parsedUrl.search = params.toString()

    history.replaceState(null, '', parsedUrl.toString())
  }

  const router = sentryCreateBrowserRouter([
    {
      path: '/',
      element: <PageLayout/>,
      errorElement: (
        <SuspensePage>
          <ErrorPage/>
        </SuspensePage>
      ),
      children: [
        {
          children: [
            {index: true,
              loader: homeLoader,
              element: (
                <SuspensePage>
                  <Home/>
                </SuspensePage>
              )
            },
            {
              path: '/account',
              element: (
                <SuspensePage>
                  <AccountLayout>
                    <Outlet/>
                  </AccountLayout>
                </SuspensePage>
              ),
              children: [
                {
                  path: '/account',
                  element: (
                    <UserTabsNavigation activeTab='overview'>
                      <Account/>
                    </UserTabsNavigation>
                  ),
                  loader: accountLoader
                },
                {
                  path: '/account/organizations',
                  element: (
                    <UserTabsNavigation activeTab='organizations'>
                      <AccountOrganizations/>
                    </UserTabsNavigation>
                  ),
                  loader: accountOrganizationLoader
                },
                {
                  path: '/account/projects',
                  element: (
                    <UserTabsNavigation activeTab='projects'>
                      <AccountProjects/>
                    </UserTabsNavigation>
                  ),
                  loader: accountProjectsLoader
                },
                {
                  path: '/account/settings',
                  element: (
                    <UserTabsNavigation activeTab='settings'>
                      <AccountSettingsLayout/>
                    </UserTabsNavigation>
                  ),
                  children: [
                    {index: true,
                      element: <AccountSettings/>
                    },
                    {
                      path: '/account/settings/plan',
                      element: <Plan/>
                    },
                    {
                      path: '/account/settings/integrations',
                      element: <AccountIntegrations/>
                    }
                  ]
                },
                {
                  path: '/account/projects/new',
                  element: <NewProject/>,
                  loader: newProjectLoader
                },
                {
                  path: '/account/organizations/new',
                  element: <NewOrganization/>
                }
              ]
            },
            {
              path: '/projects/:projectId',
              element: (
                <RequireAuth>
                  <ProjectLayout/>
                </RequireAuth>
              ),
              loader: projectLoader,
              children: [
                {index: true,
                  loader: projectLoader,
                  element: (
                    <SuspensePage>
                      <Project/>
                    </SuspensePage>
                  )
                },
                {
                  path: '/projects/:projectId/workflows',
                  children: [
                    {index: true,
                      loader: workflowsLoader,
                      element: (
                        <SuspensePage>
                          <Workflows/>
                        </SuspensePage>
                      )
                    },
                    {
                      path: '/projects/:projectId/workflows/:workflowId',
                      loader: workflowLoader,
                      element: (
                        <SuspensePage>
                          <Workflow/>
                        </SuspensePage>
                      )
                    }
                  ]
                },
                {
                  path: '/projects/:projectId/files-containers',
                  loader: projectFileContainersLoader,
                  element: (
                    <SuspensePage>
                      <FilesContainers/>
                    </SuspensePage>
                  )
                },
                {
                  path: '/projects/:projectId/settings',
                  element: <ProjectSettingsLayout/>,
                  loader: projectLoader,
                  children: [
                    {index: true,
                      element: (
                        <SuspensePage>
                          <ProjectSettings/>
                        </SuspensePage>
                      )
                    }
                  ]
                },
                {
                  path: '/projects/:projectId/settings/datasets',
                  element: <ProjectSettingsLayout/>,
                  loader: projectLoader,
                  children: [
                    {index: true,
                      element: (
                        <SuspensePage>
                          <ProjectDatasets/>
                        </SuspensePage>
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: '/organizations/:organizationId',
              loader: organizationLoader,
              element: (
                <RequireAuth>
                  <SuspensePage>
                    <OrganizationTabsNavigation activeTab='overview'>
                      <Organization/>
                    </OrganizationTabsNavigation>
                  </SuspensePage>
                </RequireAuth>
              )
            },
            {
              path: '/organizations/:organizationId/projects',
              loader: organizationLoader,
              element: (
                <RequireAuth>
                  <SuspensePage>
                    <OrganizationTabsNavigation activeTab='projects'>
                      <OrganizationProjects/>
                    </OrganizationTabsNavigation>
                  </SuspensePage>
                </RequireAuth>
              )
            },
            {
              path: '/organizations/:organizationId/settings',
              element: (
                <RequireAuth>
                  <SuspensePage>
                    <OrganizationTabsNavigation activeTab='settings'>
                      <OrganizationSettingsLayout/>
                    </OrganizationTabsNavigation>
                  </SuspensePage>
                </RequireAuth>
              ),
              loader: organizationSettingsLoader,
              children: [
                {index: true,
                  loader: organizationSettingsLoader,
                  element: (
                    <RequireAuth>
                      <SuspensePage>
                        <OrganizationSettings/>
                      </SuspensePage>
                    </RequireAuth>
                  )
                }
              ]
            },
            {
              path: '/organizations/:organizationId/members',
              loader: organizationSettingsLoader,
              element: (
                <RequireAuth>
                  <SuspensePage>
                    <OrganizationTabsNavigation activeTab='members'>
                      <Members/>
                    </OrganizationTabsNavigation>
                  </SuspensePage>
                </RequireAuth>
              )
            },
            {
              path: '/users/:userId',
              loader: profileLoader,
              element: (
                <RequireAuth>
                  <SuspensePage>
                    <UserLayout>
                      <Outlet/>
                    </UserLayout>
                  </SuspensePage>
                </RequireAuth>
              ),
              children: [
                {
                  path: '/users/:userId',
                  loader: profileLoader,
                  element: (
                    <UserTabsNavigation activeTab='overview'>
                      <Profile/>
                    </UserTabsNavigation>
                  )
                },
                {
                  path: '/users/:userId/organizations',
                  element: (
                    <UserTabsNavigation activeTab='organizations'>
                      <UserOrganizations/>
                    </UserTabsNavigation>
                  ),
                  loader: userOrganizationLoader
                },
                {
                  path: '/users/:userId/projects',
                  element: (
                    <UserTabsNavigation activeTab='projects'>
                      <UserProjects/>
                    </UserTabsNavigation>
                  ),
                  loader: userProjectsLoader
                }
              ]
            },
            {
              path: '/sign-up',
              element: <SignUp/>
            },
            {
              path: '/sign-up/success',
              element: (
                <RequireAuth>
                  <SuspensePage>
                    <SuccessfulRegistration/>
                  </SuspensePage>
                </RequireAuth>
              )
            },
            {
              path: '/welcome',
              element: (
                <RequireAuth>
                  <SuspensePage>
                    <RegistrationDone/>
                  </SuspensePage>
                </RequireAuth>
              )
            },
            {
              path: '/sign-in',
              element: <SignIn/>
            },
            {
              path: '/logout',
              element: <Logout/>
            }
          ]
        }
      ]
    }
  ])

  return <RouterProvider router={router}/>
}

export default App
