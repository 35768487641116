/* eslint-disable react/prop-types */

import Icon from '@ant-design/icons'
import {Flex, Space, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

const {Text} = Typography

function ProfileInfo({icon, title, isEditing, value}) {
  const {t} = useTranslation('common')

  return (
    <Flex vertical gap='small'>
      <Space size={4}>
        <Text strong>{title}</Text>
      </Space>
      {value && typeof value === 'object' ? (
        value
      ) : (
        <Space>
          {!isEditing && <Icon component={icon}/>}
          <Text type='secondary'>{value || t('notSpecified')}</Text>
        </Space>
      )}
    </Flex>
  )
}

export default ProfileInfo
