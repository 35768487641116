/* eslint-disable react/prop-types */

import {useContext} from 'react'

import {RollbackOutlined} from '@ant-design/icons'
import {Flex, Typography, InputNumber as InputNumberAntd, Space} from 'antd'
import {useTranslation} from 'react-i18next'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import useField from 'hooks/use-field.js'

const {Text} = Typography

function Display({label, name}) {
  const {t} = useTranslation('common')
  const {params} = useContext(OperationParamsContext)

  return (
    <Flex vertical>
      <Text>{label} :</Text>
      {params[name]
        ? <Text strong ellipsis>{params[name]}</Text>
        : <Text italic>{t('notSpecified')}</Text>}
    </Flex>
  )
}

function Field({name, placeholder, validationMessages, inputProps}) {
  const {t} = useTranslation('common')
  const {initialParams, params, updateParam} = useContext(OperationParamsContext)

  const {value, initialValue, onChangeValue, reset, handleSubmit} = useField({
    name,
    initialValue: params ? params[name] : initialParams[name],
    submit: updateParam
  })

  const hasPreviousValue = value === '' && initialValue
  const hasMin = inputProps.min !== undefined
  const hasMax = inputProps.max !== undefined

  const minText = hasMin ? t('common:input.min', {value: inputProps.min}) : ''
  const maxText = hasMax ? t('common:input.max', {value: inputProps.max}) : ''
  const separator = hasMin && hasMax ? ', ' : ''

  return (
    <Flex vertical>
      <InputNumberAntd
        value={value}
        placeholder={initialValue || placeholder}
        status={validationMessages ? 'error' : 'default'}
        {...inputProps}
        suffix={
          <RollbackOutlined
            style={{
              display: hasPreviousValue ? 'block' : 'none',
              color: 'rgba(0,0,0,.45)',
              cursor: 'pointer'
            }}
            onClick={reset}
          />
        }
        onChange={onChangeValue}
        onBlur={handleSubmit}
      />

      {(hasMin || hasMax) && (
        <Space>
          <Text type='secondary'>
            {minText}
            {separator}
            {maxText}
          </Text>
        </Space>
      )}
    </Flex>
  )
}

export default withEditable(Field, Display)
