import {CloudDownloadOutlined} from '@ant-design/icons'

export {Params} from './params.js'

export const type = 'input'
export const label = 'batch-download'
export const icon = CloudDownloadOutlined
export const defaultValues = {
  disableHtmlCheck: false,
  allowedStatusCodes: [200],
  downloadConcurrency: 5,
  maxRetry: 5,
  responseTimeout: 5000
}
