import React from 'react'

import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Container from 'containers/layout/container.js'

const StyledCol = styled(Col)`
  width: 100%;
`
function SidebarLayout({main, sidebar, isSidebarFirst = false}) {
  return (
    <Container>
      <Row style={{flex: 1}} gutter={[12, 12]}>
        <StyledCol order={isSidebarFirst ? 1 : 0} md={24} lg={18}>
          {main}
        </StyledCol>
        <StyledCol order={isSidebarFirst ? 0 : 1} md={24} lg={6}>
          {sidebar}
        </StyledCol>
      </Row>
    </Container>
  )
}

SidebarLayout.propTypes = {
  main: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
  isSidebarFirst: PropTypes.bool
}

export default SidebarLayout
