/* eslint-disable react/prop-types */

import {Col, Flex, Layout, Menu, Row} from 'antd'
import styled from 'styled-components'

const {Content, Sider} = Layout

const Illustration = styled.img`
    position: absolute;
    width: 80%;
    max-width: 600px;
`

const IllustratedLayoutStyled = styled(Row)`
  flex: 1;

  .title {
    margin: 0;
  }

  .icon {
    width: 80px;
  }

  .full-height {
    height: 100%;
  }

  .illustration-container {
    width: 100%;
    height: 500px;
  }
`

const SettingLayoutContainer = styled.div`
    display: flex;
    flex: auto;

    .layout-content {
        padding: ${({theme}) => theme.antd.padding}px;
    }

    .content {
        padding: ${({theme}) => theme.antd.padding}px;
        margin: 0;
        background-color: ${({theme}) => theme.antd.contentBgWhite};
        border-radius: ${({theme}) => theme.antd.borderRadius}px;
        min-width: auto;
        height: 100%;
    }
`
SettingLayoutContainer.displayName = 'SettingLayoutContainer'

const SiderContent = styled(Flex)`
    background: ${({theme}) => theme.antd.contentBgWhite};
    padding: ${({theme}) => theme.antd.padding}px;
    height: 100%;

    .settings-menu {
        flex: 1;
        padding: 0;
    }
`

SiderContent.displayName = 'SiderContent'

const styles = {
  siderMenu: {
    borderInlineEnd: 0
  }
}

function SettingsLayout({menuProps, siderHeader, children}) {
  return (
    <SettingLayoutContainer>
      <Layout>
        <Sider width={240} breakpoint='md'>
          <SiderContent vertical gap='middle'>
            {siderHeader}

            <Menu
              mode='inline'
              className='settings-menu'
              style={styles.siderMenu}
              {...menuProps}
            />
          </SiderContent>
        </Sider>
        <Layout className='layout-content'>
          <IllustratedLayoutStyled>
            <Col md={24} lg={24} xl={12}>
              <Content className='content'>
                {children}
              </Content>
            </Col>

            <Col lg={0} xl={12}>
              <Flex vertical className='full-height' justify='center'>
                <Flex className='illustration-container' justify='center' align='center'>
                  <Illustration src='/images/setting-illustration.svg'/>
                </Flex>
              </Flex>
            </Col>
          </IllustratedLayoutStyled>
        </Layout>
      </Layout>
    </SettingLayoutContainer>
  )
}

export default SettingsLayout
