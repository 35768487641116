import {useMemo} from 'react'

import {Tabs} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {styled} from 'styled-components'

import OperationParamsWrapper from 'containers/operation-params-wrapper.js'
import OperationResult from 'containers/workflow-editor/operation-side/operation-result.js'

const StickyTabs = styled(Tabs)`
  &:first-child > :first-child {
    background-color: ${({theme}) => theme.antd.colorWhite};
    position: sticky;
    top: -16px;
    z-index: 2;
  }
`

function OperationTabs({activeKey, handleChange, ...props}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.OperationSider'})

  const {operation, progress, execution} = props

  const items = useMemo(() => [
    {
      key: 'params',
      label: t('paramsTabLabel'),
      children: <OperationParamsWrapper {...props}/>
    },
    {
      key: 'result',
      label: t('resultTabLabel'),
      disabled: Boolean(!execution && !progress && operation.executionStatus === 'running'),
      children: <OperationResult {...props}/>
    }
  ], [operation, progress, execution, props, t])

  return (
    <StickyTabs activeKey={activeKey} items={items} onChange={handleChange}/>
  )
}

OperationTabs.propTypes = {
  activeKey: PropTypes.oneOf(['params', 'result']).isRequired,
  operation: PropTypes.object.isRequired,
  OperationParams: PropTypes.func.isRequired,
  progress: PropTypes.object,
  execution: PropTypes.object,
  handleChange: PropTypes.func.isRequired
}

export default OperationTabs
