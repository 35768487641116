/* eslint-disable react/prop-types, no-unused-vars */

import ParamsStep from 'components/display/operation-params/params-step.js'

import Checkbox from 'containers/form/checkbox.js'
import InputNumber from 'containers/form/input-number.js'
import Input from 'containers/form/input.js'
import PasswordInput from 'containers/form/password-input.js'
import SelectTag from 'containers/form/select-tag.js'
import UrlsList from 'containers/form/urls-list.js'
import ParamsTimeline from 'containers/params-timeline.js'

export function Params({params, inputContext, preparationContext, triggerPreparation, t}) {
  return (
    <ParamsTimeline>
      <ParamsStep
        title={t('distantPaths')}
        checks={['resolvedFiles', 'fileNamesUniqueness']}
        hint='Séparez les URL par des retours à la ligne.'
        triggerPreparation={triggerPreparation}
      >
        <UrlsList
          isRequired
          t={t}
          label={t('params.urlList')}
          name='urlList'
          placeholder={t('params.urlPlaceholder')}
          separator={'\n'}
          resolvedFiles={preparationContext?.result?.resolvedFiles}
        />
      </ParamsStep>

      <ParamsStep
        title={t('authentication')}
        checks={['authorization']}
        triggerPreparation={triggerPreparation}
      >
        <Input
          label={t('params.username')}
          name='username'
          placeholder={t('params.username')}
        />

        <PasswordInput
          label={t('common:form.password')}
          name='password'
        />
      </ParamsStep>

      <ParamsStep
        title={t('advancedParams')}
        checks={['statusCode', 'contentType']}
        triggerPreparation={triggerPreparation}
      >
        <Checkbox
          label={t('params.disableHtmlCheck')}
          name='disableHtmlCheck'
        />

        <SelectTag
          label={t('params.allowedStatusCodes')}
          name='allowedStatusCodes'
        />

        <InputNumber
          label={t('params.downloadConcurrency')}
          name='downloadConcurrency'
          inputProps={{
            min: 1,
            max: 8
          }}
        />

        <InputNumber
          label={t('params.maxRetry')}
          name='maxRetry'
          inputProps={{
            min: 1,
            max: 20
          }}
        />

        <InputNumber
          label={t('params.responseTimeout')}
          name='responseTimeout'
          inputProps={{
            min: 200,
            max: 30_000,
            addonAfter: t('common:unit.milliseconds')
          }}
        />
      </ParamsStep>
    </ParamsTimeline>
  )
}
