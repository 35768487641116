import pRetry from 'p-retry'

import {
  askRegistration,
  askEmailAuthentication,
  getAccountProfile,
  getAccountOrganizations,
  getAccountInvitations,
  updateAccountProfile,
  updateAccountProfileAvatar,
  deleteAccountProfileAvatar,
  createAccountProject,
  getAccountProjects
} from './account.js'
import {
  createProjectFileList,
  getProjectFileLists,
  getFileList,
  getFileListItems,
  deleteFileList,
  closeFileList,
  getFileListInteractiveInstance,
  publishFileList
} from './file-list.js'
import {
  getOrganization,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  updateOrganizationAvatar,
  deleteOrganizationAvatar,
  createOrganizationProject,
  getOrganizationProjects,
  getOrganizationInvitations,
  resendInvitation,
  cancelInvitation,
  acceptInvitation,
  declineInvitation,
  searchUsersByEmail,
  inviteUserToOrganization,
  removeUserFromOrganization
} from './organizations.js'
import {
  getProject,
  getContainers,
  deleteContainer,
  getWorkflow,
  getProjectWorkflows,
  createWorkflow,
  updateProject,
  deleteProject,
  getAvailableOperations,
  updateWorkflow,
  deleteWorkflow,
  getLicenses
} from './projects.js'
import {
  getUser,
  getUserOrganizations,
  getUserProjects
} from './user.js'
import {API_URL, createHttpError, executeRequest, createEventChannel} from './util/request.js'
import {
  getWorkspace,
  stopWorkspace,
  joinWorkspace,
  keepWorkspaceAlive,
  saveWorkspaceIntoWorkflow,
  getOperations,
  createOperation,
  updateWorkflowRelations,
  createWorkspace,
  deleteWorkspace,
  recreateWorkspace,
  getWorkflowWorkspaces,
  getCurrentUserWorkspaces,
  getOperation,
  updateOperation,
  deleteOperation,
  prepareOperation,
  getPreparation,
  getPreparationByHash,
  executeOperation,
  getExecution,
  getExecutionByHash,
  abortOperation
} from './workspaces.js'

const SESSION_LOCALSTORAGE_KEY = 'session'

function createInMemoryStorage() {
  const storage = new Map()

  return {
    getItem(key) {
      return storage.get(key)
    },
    setItem(key, value) {
      if (typeof value !== 'string') {
        throw new TypeError('Value must be a string')
      }

      storage.set(key, value)
    },
    removeItem(key) {
      storage.delete(key)
    }
  }
}

class Client {
  constructor() {
    this.storage = typeof localStorage === 'undefined' ? createInMemoryStorage() : localStorage
    this.authenticated = false
    this.cache = new Map()
  }

  async init() {
    if (!this.initPromise) {
      this.initPromise = new Promise(async resolve => { // eslint-disable-line no-async-promise-executor
        try {
          await this.readStoredSession()
        } finally {
          resolve()
        }
      })
    }

    return this.initPromise
  }

  async readStoredSession() {
    const entry = this.storage.getItem(SESSION_LOCALSTORAGE_KEY)

    if (!entry) {
      return
    }

    const parsedValue = JSON.parse(entry)

    if (!parsedValue || !parsedValue.token) {
      return
    }

    this.session = parsedValue

    try {
      const profile = await pRetry(
        () => getAccountProfile({client: this, dropAuthentication: true}),
        {retries: 10, onFailedAttempt: () => console.log('Retrying to validate session token...')}
      )

      this.authenticated = true
      this.user = profile
    } catch {
      this.clearSession()
    }
  }

  async refreshUser() {
    const user = await this.getAccountProfile()
    this.user = user
  }

  async storeSession(session) {
    if (this.authenticated) {
      return
    }

    this.storage.setItem(SESSION_LOCALSTORAGE_KEY, JSON.stringify(session))
    this.session = session
    this.initPromise = null

    return this.init()
  }

  async clearSession() {
    this.storage.removeItem(SESSION_LOCALSTORAGE_KEY)
    this.authenticated = false
    delete this.user
    delete this.session
  }

  async ensureAuthenticated() {
    await this.init()
    if (!this.authenticated) {
      throw createHttpError(401, 'Not authenticated')
    }
  }

  async askRegistration(body) {
    return askRegistration({client: this, body})
  }

  async askEmailAuthentication(body) {
    return askEmailAuthentication({client: this, body})
  }

  async getAccountProfile() {
    return getAccountProfile({client: this})
  }

  async getAccountOrganizations() {
    return getAccountOrganizations({client: this})
  }

  async getAccountInvitations() {
    return getAccountInvitations({client: this})
  }

  async deleteOrganizationAvatar(organizationId) {
    return deleteOrganizationAvatar({client: this, organizationId})
  }

  async updateAccountProfile(body) {
    return updateAccountProfile({client: this, body})
  }

  async updateAccountProfileAvatar(file) {
    return updateAccountProfileAvatar({client: this, file})
  }

  async deleteAccountProfileAvatar() {
    return deleteAccountProfileAvatar({client: this})
  }

  async createAccountProject(body) {
    return createAccountProject({client: this, body})
  }

  async getAccountProjects() {
    return getAccountProjects({client: this})
  }

  async getOrganization(organizationId) {
    return getOrganization({client: this, organizationId})
  }

  async createOrganization(organization) {
    return createOrganization({client: this, organization})
  }

  async updateOrganization(organizationId, changes) {
    return updateOrganization({client: this, organizationId, changes})
  }

  async deleteOrganization(organizationId) {
    return deleteOrganization({client: this, organizationId})
  }

  async updateOrganizationAvatar(organizationId, file) {
    return updateOrganizationAvatar({client: this, organizationId, file})
  }

  async createOrganizationProject(organizationId, project) {
    return createOrganizationProject({client: this, organizationId, project})
  }

  async getOrganizationProjects(organizationId) {
    return getOrganizationProjects({client: this, organizationId})
  }

  async getOrganizationInvitations(organizationId) {
    return getOrganizationInvitations({client: this, organizationId})
  }

  async inviteUserToOrganization(organizationId, userId) {
    return inviteUserToOrganization({client: this, organizationId, userId})
  }

  async resendInvitation(invitationId) {
    return resendInvitation({client: this, invitationId})
  }

  async cancelInvitation(invitationId) {
    return cancelInvitation({client: this, invitationId})
  }

  async acceptInvitation(invitationId) {
    return acceptInvitation({client: this, invitationId})
  }

  async declineInvitation(invitationId) {
    return declineInvitation({client: this, invitationId})
  }

  async removeUserFromOrganization(organizationId, userId) {
    return removeUserFromOrganization({client: this, organizationId, userId})
  }

  async searchUsersByEmail(email) {
    return searchUsersByEmail({client: this, email})
  }

  async getAllProjects() {
    await this.ensureAuthenticated()
    return executeRequest({url: '/projects', method: 'GET', client: this})
  }

  async getProject(projectId) {
    return getProject({client: this, projectId})
  }

  async getLicenses() {
    return getLicenses({client: this})
  }

  async getContainers(projectId) {
    return getContainers({client: this, projectId})
  }

  async getWorkflow(workflowId) {
    return getWorkflow({client: this, workflowId})
  }

  async getProjectWorkflows(projectId) {
    return getProjectWorkflows({client: this, projectId})
  }

  async createWorkflow(projectId) {
    return createWorkflow({client: this, projectId})
  }

  async getOperation(operationId) {
    return getOperation({client: this, operationId})
  }

  async updateOperation(operationId, changes) {
    return updateOperation({client: this, operationId, changes})
  }

  async deleteOperation(operationId) {
    return deleteOperation({client: this, operationId})
  }

  async prepareOperation(operationId) {
    return prepareOperation({client: this, operationId})
  }

  async getPreparation(operationId) {
    return getPreparation({client: this, operationId})
  }

  async getPreparationByHash(workspaceId, operationHash) {
    return getPreparationByHash({client: this, workspaceId, operationHash})
  }

  async executeOperation(operationId) {
    return executeOperation({client: this, operationId})
  }

  async getExecution(operationId) {
    return getExecution({client: this, operationId})
  }

  async getExecutionByHash(workspaceId, operationHash) {
    return getExecutionByHash({client: this, workspaceId, operationHash})
  }

  async abortOperation(operationId) {
    return abortOperation({client: this, operationId})
  }

  async getAvailableOperations(projectId) {
    return getAvailableOperations({client: this, projectId})
  }

  async updateProject(projectId, changes) {
    return updateProject({client: this, projectId, changes})
  }

  async deleteProject(projectId) {
    return deleteProject({client: this, projectId})
  }

  async updateWorkflow(workflowId, changes) {
    return updateWorkflow({client: this, workflowId, changes})
  }

  async deleteWorkflow(workflowId) {
    return deleteWorkflow({client: this, workflowId})
  }

  async getWorkspace(workspaceId) {
    return getWorkspace({client: this, workspaceId})
  }

  async stopWorkspace(workspaceId, force) {
    return stopWorkspace({client: this, workspaceId, force})
  }

  async joinWorkspace(workspaceId) {
    return joinWorkspace({client: this, workspaceId})
  }

  async keepWorkspaceAlive(workspaceId) {
    return keepWorkspaceAlive({client: this, workspaceId})
  }

  async saveWorkspaceIntoWorkflow(workspaceId) {
    return saveWorkspaceIntoWorkflow({client: this, workspaceId})
  }

  async getOperations(workspaceId) {
    return getOperations({client: this, workspaceId})
  }

  async createOperation(workspaceId, operation) {
    return createOperation({client: this, workspaceId, operation})
  }

  async getUser(userId) {
    return getUser({client: this, userId})
  }

  async getUserOrganizations(userId) {
    return getUserOrganizations({client: this, userId})
  }

  async getUserProjects(userId) {
    return getUserProjects({client: this, userId})
  }

  async updateWorkflowRelations(workspaceId, relations) {
    return updateWorkflowRelations({client: this, workspaceId, relations})
  }

  async createWorkspace(workflowId) {
    return createWorkspace({client: this, workflowId})
  }

  async deleteWorkspace(workspaceId) {
    return deleteWorkspace({client: this, workspaceId})
  }

  async recreateWorkspace(workspaceId) {
    return recreateWorkspace({client: this, workspaceId})
  }

  async getWorkflowWorkspaces(workflowId) {
    return getWorkflowWorkspaces({client: this, workflowId})
  }

  async getCurrentUserWorkspaces() {
    return getCurrentUserWorkspaces({client: this})
  }

  async createProjectFileList(projectId) {
    return createProjectFileList({client: this, projectId})
  }

  async getProjectFileLists(projectId) {
    return getProjectFileLists({client: this, projectId})
  }

  async getFileList(fileListId) {
    return getFileList({client: this, fileListId})
  }

  async getFileListItems(fileListId) {
    return getFileListItems({client: this, fileListId})
  }

  async deleteFileList(fileListId) {
    return deleteFileList({client: this, fileListId})
  }

  async closeFileList(fileListId) {
    return closeFileList({client: this, fileListId})
  }

  async publishFileList(fileListId, containerSlug) {
    return publishFileList({client: this, fileListId, containerSlug})
  }

  async deleteContainer(projectId, containerSlug) {
    return deleteContainer({client: this, projectId, containerSlug})
  }

  getFileListInteractiveInstance(fileListId) {
    const key = `file-list:${fileListId}:interactive-instance`

    if (!this.cache.has(key)) {
      this.cache.set(key, getFileListInteractiveInstance({client: this, fileListId}))
    }

    return this.cache.get(key)
  }

  /* Event channels */

  eventChannel(channelUrl) {
    return createEventChannel(channelUrl, this)
  }

  /* Util */

  async getSessionToken() {
    await this.init()
    return this.session ? this.session.token : null
  }

  async isAuthenticated() {
    await this.init()
    return this.authenticated
  }

  getAvatarURL(profile, size = 'small') {
    if (!profile.avatarKey) {
      return null
    }

    // Duck typing to check if profile is a user or an organization. Sorry for this.
    const isUser = profile.fullName || profile.type === 'user'

    const profilePart = isUser ? `users/${profile._id}` : `organizations/${profile._id}`
    return `${API_URL}/${profilePart}/avatar/${profile.avatarKey}/${size}`
  }
}

const client = new Client()
export default client
