import React from 'react'

import {truncate} from 'lodash-es'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'

const {REACT_APP_HOSTNAME} = process.env

function Meta({title, description = null}) {
  const {t} = useTranslation('common')

  const metaDescription = truncate(description || t('catchphrase'), {
    length: 160,
    omission: '…'
  })

  const metaTitle = title === 'Living Data'
    ? title
    : `${title} | Living Data`

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <link rel='icon' type='image/x-icon' href={`${REACT_APP_HOSTNAME}/favicon.ico`}/>
      <meta name='description' content={metaDescription}/>
      <meta property='author' content='livingdata.co'/>
      <meta property='og:title' content={metaTitle}/>
      <meta property='og:type' content='website'/>
      <meta property='og:description' content={metaDescription}/>
      <meta property='og:image' content={`${REACT_APP_HOSTNAME}/images/meta-card.png`}/>
      <meta name='twitter:creator' content='@_LivingData'/>
      <meta name='twitter:title' content={metaTitle}/>
      <meta name='twitter:description' content={metaDescription}/>
      <meta name='twitter:image' content={`${REACT_APP_HOSTNAME}/images/meta-card.png`}/>
      <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
    </Helmet>
  )
}

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default Meta

