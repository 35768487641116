import {AuditOutlined, ExpandOutlined, HistoryOutlined, HourglassOutlined, LockOutlined, SyncOutlined, UnlockOutlined} from '@ant-design/icons'
import {Divider, Flex, Tag, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {styled} from 'styled-components'

import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'

import FullNameAvatar from '../avatar/full-name-avatar.js'

const {Text} = Typography

const LightText = styled(Text)`
  font-weight: ${({theme}) => theme.antd.fontWeightLight};
`

const ContainerSpace = styled(Flex)`
  padding: ${({theme}) => theme.antd.padding}px;
  font-weight: ${({theme}) => theme.antd.fontWeightLight};
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  border: 1px solid ${({theme}) => theme.token.borderLight};
  width: 100%;

  .no-margin {
    margin: 0;
  }

  .strong-text {
    font-size: ${({theme}) => theme.antd.fontSizeSM}px;
  }

  .icon-margin {
    margin-right: ${({theme}) => theme.antd.marginXS}px;
  }

  .tag-margin {
    margin: ${({theme}) => `${theme.antd.marginXXS}px`};
  }
`
ContainerSpace.displayName = 'ProjectMeta'

function ProjectMeta({
  fullName,
  ownerType,
  avatar,
  creationDate,
  lastUpdate,
  isDataset,
  dataset
}) {
  const {t} = useTranslation(['translation', 'common'])

  const Avatar = ownerType === 'organization' ? OrganizationAvatar : UserAvatar

  const {updateFrequency, licenses, spatialCoverage} = dataset

  return (
    <ContainerSpace
      vertical
      gap='middle'
    >
      <FullNameAvatar fullName={fullName} avatarPosition='top'>
        <Avatar avatar={avatar} size={ownerType === 'organization' ? 100 : 80}/>
      </FullNameAvatar>

      <Divider className='no-margin'/>

      <Text strong className='no-margin'>{t('ProjectMeta.title')}</Text>
      <Flex vertical gap='small'>
        <Text strong className='strong-text'>
          <HourglassOutlined className='icon-margin'/>{t('common:date.createdSince')}<LightText>{t('common:date.since', {since: creationDate})}</LightText>
        </Text>
        <Text strong className='strong-text'>
          <HistoryOutlined className='icon-margin'/>{t('common:date.updatedSince')}<LightText>{t('common:date.since', {since: lastUpdate})}</LightText>
        </Text>
        {isDataset && (
          <>
            <Text strong className='strong-text'>
              <SyncOutlined className='icon-margin'/>
              <span>{t('translation:Project.Dataset.updateFrequency')} : </span>
              <LightText>{t(`translation:Project.Dataset.periodicity.${updateFrequency}`)}</LightText>
            </Text>
            <Text strong className='strong-text'>
              <UnlockOutlined className='icon-margin'/>
              <span>{t('translation:Project.Dataset.access')} : </span>
              {licenses?.length > 0
                ? <Tag color='blue' icon={<UnlockOutlined/>} className='tag-margin'>{t('translation:Project.Dataset.public')}</Tag>
                : <Tag color='orange' icon={<LockOutlined/>} className='tag-margin'>{t('translation:Project.Dataset.restricted')}</Tag>}
            </Text>
            <Text strong className='strong-text'>
              <ExpandOutlined className='icon-margin'/>
              <span>{t('translation:Project.Dataset.spatialCoverage')} : </span>
              <LightText>{spatialCoverage}</LightText>
            </Text>
            {licenses?.length > 0 && (
              <Text strong className='strong-text'>
                <AuditOutlined className='icon-margin'/>
                <span>{t('translation:Project.Dataset.licenses')} : </span>
                {licenses.map(l => (
                  <Tag key={l.id} className='tag-margin'>{l.name}</Tag>
                ))}
              </Text>
            )}
          </>
        )}
      </Flex>
    </ContainerSpace>
  )
}

ProjectMeta.propTypes = {
  fullName: PropTypes.string.isRequired,
  ownerType: PropTypes.oneOf(['organization', 'user']).isRequired,
  avatar: PropTypes.string,
  creationDate: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired,
  isDataset: PropTypes.bool,
  dataset: PropTypes.object,
  licenses: PropTypes.array
}

export default ProjectMeta
