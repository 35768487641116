/* eslint-disable react/prop-types */

import React from 'react'

import Icon, {PlusCircleOutlined} from '@ant-design/icons'
import {Space, Flex, Typography, Grid, Button, Skeleton} from 'antd'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

const {useBreakpoint} = Grid

const SectionStyled = styled(Flex)`
  height: 100%;
  border-radius: 8px;
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border: 1px solid ${({theme}) => theme.token.borderLight};
  .title {
    margin-top: 0;
  }

  .link-container {
    padding: ${({theme}) => theme.antd.padding}px;
  }
`
SectionStyled.displayName = 'Section'

const SectionContainer = styled.div`
  flex: 1;

  .children-container {
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    height: 100%;
    padding: ${({theme}) => theme.antd.padding}px;
  }
`
const SectionHeader = styled(Flex)`
padding: ${({theme}) => theme.antd.padding}px;
`
function Section({children, title, icon, link, isLoading = false, onAdd, buttonLabel}) {
  const {t} = useTranslation('common')

  const screens = useBreakpoint()

  return (
    <SectionStyled vertical gap='small'>
      <SectionHeader justify='space-between' align='baseline'>
        {(title || icon) && (
          <Typography.Title className='title' level={4}>
            <Space size='middle'>
              <Icon component={icon}/>
              {title}
            </Space>
          </Typography.Title>
        )}

        {onAdd && buttonLabel && (
          <Button
            type='primary'
            icon={<PlusCircleOutlined/>}
            onClick={onAdd}
          >
            {screens.md ? buttonLabel : null}
          </Button>
        )}
      </SectionHeader>

      <SectionContainer>
        <Skeleton active loading={isLoading}>
          <div className='children-container'>
            {children}
          </div>
          <Flex className='link-container' justify='end'>
            {link && <Link to={link.href}>{link.label || t('button.showAll')}</Link>}
          </Flex>
        </Skeleton>
      </SectionContainer>
    </SectionStyled>
  )
}

export default React.memo(Section)
