/* eslint-disable react/prop-types */

import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'

import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {Flex, Typography, Input, Card, Space, List, Button, theme, Tag, Popover} from 'antd'
import {uniq} from 'lodash'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import OperationParamsContext from 'contexts/operation-params-context.js'

import withEditable from 'hoc/with-editable.js'

import EllipsisMiddleText from 'components/ui/ellipsis-url.js'
import FileSize from 'components/ui/file-size.js'

const StyledCard = styled(Card)`
  width: 100%;
  
  .ant-card-body {
    padding: ${({theme}) => theme.antd.padding}px;
  }
`

const BorderedPopOverContent = styled(Space)`
  border: ${({theme}) => `1px solid ${theme.antd.colorBorder}`};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  width: 100%;
  padding: ${({theme}) => theme.antd.padding}px;

  & .ant-space-item {
    width: 100%;
  }
`

const {Text} = Typography
const {TextArea} = Input

const styles = {
  fullWidth: {
    width: '100%'
  }
}

function EditUrl({url, onEdit, onDelete}) {
  const [input, setInput] = useState(url)

  return (
    <Flex>
      <Input
        value={input}
        onChange={e => setInput(e.target.value)}
        onPressEnter={() => onEdit(input)}
      />
      <Button
        type='text'
        icon={<CheckOutlined/>}
        disabled={input.length === 0}
        onClick={() => onEdit(input)}
      />
      <Button
        danger
        type='text'
        icon={<DeleteOutlined/>}
        onClick={onDelete}
      />
    </Flex>
  )
}

function ResolvedFileChecks({checks, t}) {
  const failedChecks = checks.filter(({status}) => status === 'failed')
  const {token} = theme.useToken()

  return (
    <Space
      direction='vertical'
      style={{display: 'flex', maxWidth: 250}}
    >
      {failedChecks.map(({name, error}) => (
        <Space
          key={name}
          direction='vertical'
          style={styles.fullWidth}
        >
          <Space>
            <ExclamationCircleOutlined style={{color: token.red}}/>
            <Text type='danger'>
              {t(`checks.${name}`)}
            </Text>
          </Space>
          <Text ellipsis type='secondary'>
            {error}
          </Text>
        </Space>
      ))}
    </Space>
  )
}

function ResolvedFileTooltip({resolvedFile, t}) {
  const {contentLength, finalUrl, computedFileName, contentType} = resolvedFile
  const maxWidth = Math.max(400, 10 * computedFileName.length) // 400px min or 10px per character

  return (
    <Space direction='vertical' style={{maxWidth}}>
      <Text ellipsis strong>{computedFileName}</Text>

      <BorderedPopOverContent>
        <Space size='large'>
          <Space direction='vertical'>
            <Text strong>
              {t('common:file.size')}
            </Text>
            <Text>
              <FileSize bytes={contentLength}/>
            </Text>
          </Space>

          <Space direction='vertical'>
            <Text strong>
              {t('common:file.type')}
            </Text>
            <Tag>{contentType}</Tag>
          </Space>
        </Space>
      </BorderedPopOverContent>

      <BorderedPopOverContent>
        <Space style={styles.fullWidth} direction='vertical'>
          <Text strong>
            URL
          </Text>
          <Text
            style={{display: 'flex', width: '100%'}}
            copyable={{tooltips: [finalUrl, t('common:copyableTooltip.copied')]}}
          >
            <EllipsisMiddleText text={finalUrl}/>
          </Text>
        </Space>
      </BorderedPopOverContent>
    </Space>
  )
}

function ResolvedFile({resolvedFile, t, onEdit}) {
  const {token} = theme.useToken()
  const allChecksOk = resolvedFile.checks.every(({status}) => status === 'ok')

  return (
    <Flex
      gap={8}
      align='center'
      style={{
        cursor: 'pointer'
      }}
    >
      {allChecksOk ? (
        <CheckCircleOutlined style={{color: token.green}}/>
      ) : (
        <ExclamationCircleOutlined style={{color: token.red}}/>
      )}

      <Popover
        content={allChecksOk ? (
          <ResolvedFileTooltip resolvedFile={resolvedFile} t={t}/>
        ) : (
          <ResolvedFileChecks checks={resolvedFile.checks} t={t}/>
        )}
      >
        <Text ellipsis style={styles.fullWidth}>
          <EllipsisMiddleText text={resolvedFile.url}/>
        </Text>
      </Popover>

      <Button
        type='text'
        icon={<EditOutlined/>}
        onClick={onEdit}
      />
    </Flex>
  )
}

function ResolvedFiles({resolvedFiles, onReplaceUrl, t}) {
  const [editingUrl, setEditingUrl] = useState(null)

  const handleEdit = useCallback((currentUrl, newUrl) => {
    onReplaceUrl(currentUrl, newUrl)
    setEditingUrl(null)
  }, [onReplaceUrl])

  return (
    <StyledCard>
      <Space
        direction='vertical'
        style={styles.fullWidth}
      >
        {resolvedFiles.map(resolvedFile => (
          <div key={resolvedFile.url}>
            {resolvedFile.url === editingUrl ? (
              <EditUrl
                url={resolvedFile.url}
                onEdit={newValue => handleEdit(resolvedFile.url, newValue)}
                onDelete={() => handleEdit(resolvedFile.url, '')}
              />
            ) : (
              <ResolvedFile
                resolvedFile={resolvedFile}
                t={t}
                onEdit={() => setEditingUrl(resolvedFile.url)}
              />
            )}
          </div>
        ))}
      </Space>
    </StyledCard>
  )
}

function Display({label, name}) {
  const {t} = useTranslation('common')
  const {params} = useContext(OperationParamsContext)

  return (
    <Flex vertical>
      <Text>{label} :</Text>
      {params[name]
        ? (
          <List
            dataSource={params[name]}
            renderItem={item => (
              <List.Item style={styles.fullWidth}>
                <Flex style={styles.fullWidth} gap={4}>
                  <FileOutlined/>
                  <Text
                    style={{display: 'flex', width: 'calc(100% - 20px)'}}
                    copyable={{tooltips: [item, t('common:copyableTooltip.copied')]}}
                  >
                    <EllipsisMiddleText text={item}/>
                  </Text>
                </Flex>
              </List.Item>
            )}
          />
        )
        : <Text italic>{t('notSpecified')}</Text>}
    </Flex>
  )
}

function Field({name, placeholder, separator = '\n', resolvedFiles, t}) {
  const {initialParams, params, updateParam, setHasFormValuesChanged} = useContext(OperationParamsContext)

  const [hasResolvedFiles, setHasResolvedFiles] = useState(false)
  const [isInputDisplayed, setIsInputDisplayed] = useState(true)
  const [input, setInput] = useState('')

  const initialValue = useMemo(() => {
    const paramValue = params ? params[name] : initialParams[name]
    return paramValue ? paramValue.join('\n') : ''
  }, [params, initialParams, name])

  useEffect(() => {
    const hasChanged = input !== initialValue
    setHasFormValuesChanged(hasChanged)
  }, [input, initialValue, setHasFormValuesChanged])

  useEffect(() => {
    setInput(initialValue)
  }, [initialValue])

  useEffect(() => {
    const hasResolvedFiles = resolvedFiles && resolvedFiles.length > 0
    setHasResolvedFiles(hasResolvedFiles)
    setIsInputDisplayed(!hasResolvedFiles)
  }, [resolvedFiles])

  const onChange = useCallback(e => {
    setInput(e.target.value)
  }, [])

  const onSubmit = useCallback(async () => {
    const value = uniq(
      input
        .split(separator)
        .map(url => url.trim())
        .filter(url => url !== '')
    )

    await updateParam({name, value: value.length === 0 ? undefined : value})
  }, [input, name, separator, updateParam])

  const onReset = useCallback(() => {
    setInput('')
    setIsInputDisplayed(false)
    updateParam({name, value: []})
  }, [name, updateParam])

  const onReplaceUrl = useCallback((currentUrl, newUrl) => {
    const newInput = input.replace(currentUrl, newUrl)
    setInput(newInput)

    const value = newInput
      .split(separator)
      .map(url => url.trim())
      .filter(url => url !== '')

    updateParam({name, value: value.length === 0 ? undefined : value})
  }, [input, name, separator, updateParam])

  const toggleInput = useCallback(() => {
    if (isInputDisplayed) {
      // Reset input value
      setInput(initialValue)
      setIsInputDisplayed(false)
    } else {
      setIsInputDisplayed(true)
    }
  }, [isInputDisplayed, initialValue])

  return (
    <Flex vertical gap='middle'>
      {resolvedFiles?.length > 0 && (
        <ResolvedFiles
          resolvedFiles={resolvedFiles}
          t={t}
          onReplaceUrl={onReplaceUrl}
        />
      )}

      {isInputDisplayed ? (
        <Flex vertical gap='small'>
          <TextArea
            autoSize={{minRows: 3, maxRows: 6}}
            value={input}
            placeholder={initialValue || placeholder}
            onChange={onChange}
          />
          <Flex justify='end'>
            <Space>
              <Button
                disabled={input.trim().length === 0 || !hasResolvedFiles}
                onClick={toggleInput}
              >
                {t('common:button.cancel')}
              </Button>
              <Button type='primary' onClick={onSubmit}>
                {t('common:button.save')}
              </Button>
            </Space>
          </Flex>
        </Flex>
      ) : (
        <Flex justify='end'>
          <Space>
            <Button onClick={onReset}>
              {t('common:form.eraseAll')}
            </Button>
            <Button type='primary' icon={<PlusCircleOutlined/>} onClick={toggleInput}>
              {t('common:form.addUrls')}
            </Button>
          </Space>
        </Flex>
      )}

    </Flex>
  )
}

export default withEditable(Field, Display)
