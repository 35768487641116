/* eslint-disable react/prop-types */

import React, {useRef, useEffect, useState} from 'react'

function EllipsisMiddleText({text, style, className}) {
  const containerRef = useRef(null)

  const [displayedText, setDisplayedText] = useState(text)

  useEffect(() => {
    const updateDisplayedText = () => {
      const container = containerRef.current
      if (!container) {
        return
      }

      const containerWidth = container.clientWidth

      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const computedStyle = window.getComputedStyle(container)
      context.font = `${computedStyle.fontSize} ${computedStyle.fontFamily}`

      const textWidth = context.measureText(text).width
      if (textWidth <= containerWidth) {
        setDisplayedText(text)
        return
      }

      const ellipsis = '...'
      const ellipsisWidth = context.measureText(ellipsis).width
      const availableWidth = containerWidth - ellipsisWidth

      let prefix = ''
      let suffix = ''
      let prefixLength = 0
      let suffixLength = 0

      // Find the maximum length of the prefix
      let low = 0
      let high = text.length
      while (low < high) {
        const mid = Math.ceil((low + high) / 2)
        const testPrefix = text.slice(0, mid)
        const testPrefixWidth = context.measureText(testPrefix).width

        if (testPrefixWidth > availableWidth) {
          high = mid - 1
        } else {
          low = mid
        }
      }

      prefixLength = low

      // Find the maximum length of the suffix
      low = 0
      high = text.length
      while (low < high) {
        const mid = Math.ceil((low + high) / 2)
        const testSuffix = text.slice(text.length - mid)
        const testSuffixWidth = context.measureText(testSuffix).width

        if (testSuffixWidth > availableWidth) {
          high = mid - 1
        } else {
          low = mid
        }
      }

      suffixLength = low

      // Adjust the lengths to ensure the entire text fits
      prefix = text.slice(0, prefixLength)
      suffix = text.slice(text.length - suffixLength)

      while (
        context.measureText(prefix + ellipsis + suffix).width > containerWidth
        && (prefix.length > 0 || suffix.length > 0)
      ) {
        if (prefix.length > suffix.length) {
          prefix = prefix.slice(0, -1)
        } else {
          suffix = suffix.slice(1)
        }
      }

      setDisplayedText(prefix + ellipsis + suffix)
    }

    updateDisplayedText()

    const handleResize = () => {
      updateDisplayedText()
    }

    const container = containerRef.current

    if (typeof ResizeObserver !== 'undefined') {
      const resizeObserver = new ResizeObserver(() => {
        updateDisplayedText()
      })
      resizeObserver.observe(container)

      return () => {
        resizeObserver.disconnect()
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [text])

  return (
    <div
      ref={containerRef}
      style={{...style, width: '100%', overflow: 'hidden', whiteSpace: 'nowrap'}}
      className={className}
    >
      {displayedText}
    </div>
  )
}

export default EllipsisMiddleText
