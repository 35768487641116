import {PlusCircleOutlined} from '@ant-design/icons'
import {Button, Flex, Typography} from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const {Title} = Typography

const StyledPlaceholderMessage = styled(Flex)`
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => `${theme.antd.paddingXL}px`};
  text-align: center;
  background-image: url('/images/waves-light.svg');
  background-size: cover;
  background-repeat: no-repeat;
  flex-wrap: wrap-reverse;
  margin: ${({theme}) => -theme.antd.padding}px; // Offsets the default padding by applying a negative margin to prevent layout shifts without altering the component's structure.

  img {
    display: block;
    width: 100%;
    max-width: 300px;
  }

  button {
    width: min-content;
  }
`
function PlaceholderMessage({message, onAdd, buttonLabel, imgSrc, imgAlt}) {
  return (
    <Flex vertical gap='middle'>
      <StyledPlaceholderMessage
        align='center'
        justify='space-evenly'
        gap='large'
      >
        <img
          src={imgSrc}
          alt={imgAlt}
        />
        <Flex vertical gap='large' align='center'>
          <Title level={5}>{message}</Title>
          {onAdd && buttonLabel && (
            <Button
              type='primary'
              icon={<PlusCircleOutlined/>}
              onClick={onAdd}
            >
              {buttonLabel}
            </Button>
          )}
        </Flex>
      </StyledPlaceholderMessage>
    </Flex>
  )
}

PlaceholderMessage.propTypes = {
  message: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  buttonLabel: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string
}

export default PlaceholderMessage
